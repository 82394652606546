import { useContext } from 'react';
import moment from 'moment';
import { Translator } from '@context';

function BookingCell({ booking, firstDay, onClick, openingHourInt = 0, displayRoom = false }) {
  const s = moment(booking.start);
  const e = moment(booking.end);
  const translator = useContext(Translator.Context);

  const color =
    booking?.name === translator('Free')
      ? 'cyan'
      : booking?.name === translator('Réservé')
      ? 'red'
      : 'blue';

  // Compute the row on which the cell begins (based on TailwindUI design)
  const startRow = parseInt(12 * (s.hour() - openingHourInt + s.minutes() / 60) + 2);
  // Compute the length of the cell (based on TailwindUI design)
  const span = parseInt(e.diff(s, 'minutes') / 5);

  // Compute the day column of the cell
  const col = firstDay ? s.diff(firstDay, 'days') + 1 : 1;

  return (
    <li
      className={`relative mt-px col-start-${col} flex`}
      style={{ gridRow: `${startRow} / span ${span}` }}>
      <a
        href="#"
        onClick={onClick}
        className={`group absolute inset-1 flex flex-col overflow-y-auto rounded-lg bg-${color}-50 p-2 text-xs leading-5 hover:bg-${color}-100 dark:bg-${color}-400/10 dark:text-${color}-400 dark:hover:bg-${color}-500/10`}>
        <p className={`order-1 font-semibold text-${color}-700`}>{`${booking.name} - ${
          booking?.user?.username
        } ${displayRoom ? '- ' + booking?.room?.name : ''}`}</p>
        <p className={`text-${color}-500 group-hover:text-${color}-700`}>
          <time dateTime={booking.start}>
            {s.format('HH:mm')}
            {' - '}
            {e.format('HH:mm')}
          </time>
        </p>
      </a>
    </li>
  );
}

export default BookingCell;
