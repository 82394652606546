import { useState, useContext } from 'react';
import { useEffect, useRef } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
// import { DropDown } from '@components';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import BookingCell from './BookingCell';
import moment from 'moment';
import queries from '@queries';
import 'moment/locale/fr';
import { Translator, LocalAuth } from '@context';
import { Layout } from '@custom';

moment.locale('fr');

const capitalize = (str) => str[0].toUpperCase() + str.slice(1);

// const hours = Array(24)
//   .fill(0)
//   .map((_, index) => index.toString().padStart(2, '0') + 'h');

export default function MyCalendar(props) {
  const { me } = useContext(LocalAuth.Context);
  const container = useRef(null);
  const containerNav = useRef(null);
  const containerOffset = useRef(null);
  const translator = useContext(Translator.Context);

  const [currentDay, setCurrentDay] = useState(moment());

  const navigate = useNavigate();
  const { links = [], pageName = '' } = props;

  const { data: eventsRaw, isLoading: isLoadingEvents } = useQuery(
    ['booking', 'calendar', currentDay.clone().format('YYYY-MM-DD')],
    () =>
      queries
        .getAll(`/api/bookings`, {
          populate: '*',
          filters: {
            start: { $lte: currentDay?.clone().endOf('isoWeek').format('YYYY-MM-DDTHH:mm:ss') },
            end: { $gte: currentDay?.clone().startOf('isoWeek').format('YYYY-MM-DDTHH:mm:ss') },
            $or: [{ user: me.id }, { participants: { id: me.id } }]
          }
        })
        .then((res) => res?.data)
  );

  const { data: roomsRaw, isLoading: isLoadingRoom } = useQuery(['room'], () =>
    queries.getAll(`/api/rooms`).then((res) => res?.data)
  );

  useEffect(() => {
    // Set the container scroll position based on the current time.
    const currentMinute = new Date().getHours() * 60;
    container.current.scrollTop =
      ((container.current.scrollHeight -
        containerNav.current.offsetHeight -
        containerOffset.current.offsetHeight) *
        currentMinute) /
      1440;
  }, []);

  const rooms = isLoadingRoom ? [] : roomsRaw;
  const events = isLoadingEvents ? [] : eventsRaw;

  const openingHourInt =
    rooms.length !== 0 ? Math.min(...rooms.map((x) => parseInt(x?.openingHour))) : 0;
  const closingHourInt =
    rooms.length !== 0 ? Math.max(...rooms.map((x) => parseInt(x?.closingHour))) : 24;

  const newHours = Array(closingHourInt - openingHourInt + 1)
    .fill(0)
    .map((_, index) => (index + openingHourInt).toString().padStart(2, '0') + 'h');

  return (
    <Layout links={links} pageName={pageName}>
      <div className="flex h-full flex-col w-full mx-auto max-w-7xl px-4 sm:px-6 lg:px-16 pt-10">
        <header className="flex flex-none items-center justify-between border-b border-gray-200 px-6 py-4 dark:border-slate-700">
          <div className="flex flex-wrap justify-between w-full">
            <h1 className="text-base font-semibold leading-6 text-gray-900 dark:text-gray-400 whitespace-nowrap mb-2 sm:mb-0 flex items-center">
              <time dateTime={currentDay.format('YYYY-MM')}>
                {capitalize(currentDay.format('MMMM YYYY'))}
              </time>
            </h1>
            <div className="flex flex-wrap gap-4">
              <div className="relative flex items-center rounded-md bg-white shadow-sm md:items-stretch dark:bg-slate-900 dark:hover:bg-slate-700">
                <div
                  className="pointer-events-none absolute inset-0 rounded-md ring-1 ring-inset ring-gray-300 dark:ring-white/10"
                  aria-hidden="true"
                />
                <button
                  type="button"
                  className="flex items-center justify-center rounded-l-md py-2 pl-3 pr-4 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50 dark:text-gray-400 dark:hover:text-white  dark:hover:bg-slate-700"
                  onClick={() => setCurrentDay(currentDay.clone().subtract(1, 'week'))}>
                  <span className="sr-only">{translator('Previous week')}</span>
                  <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                </button>
                <button
                  type="button"
                  onClick={() => setCurrentDay(moment())}
                  className="hidden px-3.5 text-sm font-semibold text-gray-900 hover:bg-gray-50 focus:relative md:block dark:text-gray-400 dark:hover:text-white  dark:hover:bg-slate-700">
                  {translator("Aujourd'hui")}
                </button>
                <span className="relative -mx-px h-5 w-px bg-gray-300 md:hidden" />
                <button
                  type="button"
                  onClick={() => setCurrentDay(currentDay.clone().add(1, 'week'))}
                  className="flex items-center justify-center rounded-r-md py-2 pl-4 pr-3 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50 dark:text-gray-400 dark:hover:text-white dark:hover:bg-slate-700">
                  <span className="sr-only">{translator('Next week')}</span>
                  <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
              {/* <div className="hidden md:ml-4 md:flex md:items-center"> */}
              {/* <DropDown
                className="relative"
                classNameSelect="mt-0"
                data={rooms?.map((r) => ({ label: r?.name, value: r?.id }))}
                value={room}
                onChange={(e) => setRoom(e.target.value)}
                isControlled
                selectName={translator('Salle')}
              /> */}
            </div>
          </div>
          <div className="flex items-center">
            <div className="md:ml-4 md:flex md:items-center" />
            <button
              type="button"
              onClick={() => navigate('/create/booking')}
              className="ml-6 inline-flex gap-0.5 justify-center overflow-hidden text-sm font-medium transition rounded-full bg-zinc-900 py-2 px-3 text-white hover:bg-zinc-700 dark:bg-teal-400/10 dark:text-teal-400 dark:ring-1 dark:ring-inset dark:ring-teal-400/20 dark:hover:bg-teal-400/10 dark:hover:text-teal-300 dark:hover:ring-teal-300">
              {translator('Ajouter')}
            </button>
          </div>
        </header>
        <div
          ref={container}
          className="isolate flex flex-auto flex-col overflow-auto bg-white dark:bg-slate-900">
          <div
            style={{ width: '165%' }}
            className="flex max-w-full flex-none flex-col sm:max-w-none md:max-w-full dark:bg-slate-900">
            <div
              ref={containerNav}
              className="sticky top-0 z-30 flex-none bg-white shadow ring-1 ring-black ring-opacity-5 sm:pr-8 dark:bg-slate-900 dark:ring-slate-400">
              <div className="grid grid-cols-7 text-sm leading-6 text-gray-500 sm:hidden">
                {Array(7)
                  .fill(0)
                  .map((_, index) => (
                    <button
                      key={`column_min_${index}`}
                      type="button"
                      className="flex flex-col items-center pb-3 pt-2">
                      {capitalize(
                        currentDay.clone().startOf('isoWeek').add(index, 'days').format('dd')
                      )}{' '}
                      <span
                        className={`mt-1 flex h-8 w-8 items-center justify-center font-semibold ${
                          moment().isSame(
                            currentDay.clone().startOf('isoWeek').add(index, 'days'),
                            'day'
                          )
                            ? 'rounded-full bg-indigo-600 text-white'
                            : 'text-gray-900 dark:text-gray-400'
                        }`}>
                        {currentDay.clone().startOf('isoWeek').add(index, 'days').format('DD')}
                      </span>
                    </button>
                  ))}
              </div>

              <div className="-mr-px hidden grid-cols-7 divide-x divide-gray-100 border-r border-gray-100 text-sm leading-6 text-gray-500 sm:grid dark:bg-slate-900 dark:border-slate-700 dark:divide-slate-700 ">
                <div className="col-end-1 w-14" />
                {Array(7)
                  .fill(0)
                  .map((_, index) => (
                    <div
                      key={`column_full_${index}`}
                      className="flex items-center justify-center py-3">
                      <span
                        className={
                          moment().isSame(
                            currentDay.clone().startOf('isoWeek').add(index, 'days'),
                            'day'
                          ) && 'flex items-baseline'
                        }>
                        {capitalize(
                          currentDay
                            .clone()
                            .startOf('isoWeek')
                            .add(index, 'days')
                            .format('ddd')
                            .replace('.', '')
                        )}{' '}
                        <span
                          className={`items-center justify-center font-semibold ${
                            moment().isSame(
                              currentDay.clone().startOf('isoWeek').add(index, 'days'),
                              'day'
                            )
                              ? 'rounded-full bg-indigo-600 text-white ml-1.5 flex h-8 w-8 '
                              : 'text-gray-900 dark:text-gray-400'
                          }`}>
                          {currentDay.clone().startOf('isoWeek').add(index, 'days').format('DD')}
                        </span>
                      </span>
                    </div>
                  ))}
              </div>
            </div>
            <div className="flex flex-auto">
              <div className="sticky left-0 z-10 w-14 flex-none bg-white ring-1 ring-gray-100 dark:bg-slate-900 dark:ring-slate-700" />
              <div className="grid flex-auto grid-cols-1 grid-rows-1">
                {/* Horizontal lines */}
                <div
                  className="col-start-1 col-end-2 row-start-1 grid divide-y divide-gray-100 dark:divide-slate-700"
                  style={{
                    gridTemplateRows: `repeat(${
                      2 * (closingHourInt - openingHourInt + 1)
                    }, minmax(2rem, 1fr))`
                  }}>
                  <div ref={containerOffset} className="row-end-1 h-7"></div>
                  {newHours?.map((h) => (
                    <>
                      <div>
                        <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                          {h}
                        </div>
                      </div>
                      <div />
                    </>
                  ))}
                </div>

                {/* Vertical lines */}
                <div className="col-start-1 col-end-2 row-start-1 grid-rows-1 divide-x divide-gray-100 grid grid-cols-7 dark:divide-slate-700">
                  <div className="col-start-1 row-span-full" />
                  <div className="col-start-2 row-span-full" />
                  <div className="col-start-3 row-span-full" />
                  <div className="col-start-4 row-span-full" />
                  <div className="col-start-5 row-span-full" />
                  <div className="col-start-6 row-span-full" />
                  <div className="col-start-7 row-span-full" />
                  <div className="col-start-8 row-span-full w-8" />
                </div>

                {/* Events */}
                <ol
                  className="col-start-1 col-end-2 row-start-1 grid grid-cols-7 pr-8"
                  style={{
                    gridTemplateRows: `1.75rem repeat(${
                      12 * (closingHourInt - openingHourInt + 1)
                    }, minmax(0, 1fr)) auto`
                  }}>
                  {events?.map((e, index) => (
                    <BookingCell
                      key={`cell_${index}`}
                      booking={e}
                      firstDay={currentDay.clone().startOf('isoWeek')}
                      openingHourInt={openingHourInt}
                      displayRoom={true}
                      onClick={() =>
                        e?.name !== translator('Réservé')
                          ? navigate(`/edit/booking/${e?.id}`)
                          : e.preventDefault()
                      }
                    />
                  ))}
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
