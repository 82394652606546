import React, { useContext, useState } from 'react';

import { Translator, Configuration, LocalAuth } from '@context';
import { InputText, Notification } from '@components';
import { Layout } from '@custom';
import { CalendarDaysIcon } from '@heroicons/react/24/solid';
import baseUrl from '@root/config';

const Profile = (props) => {
  const { links = [], pageName = '' } = props;

  const translator = useContext(Translator.Context);
  const configuration = useContext(Configuration.Context);
  const { me } = useContext(LocalAuth.Context);

  const [showNotification, setShowNotification] = useState(false);

  const copyToClipboard = (o) => {
    navigator.clipboard.writeText(
      `${baseUrl}/api/ical?token=${sessionStorage.getItem('token')}&userId=${o.id}`
    );
    setShowNotification(true);
  };

  const displayCalendar = configuration.subscription === 'connectivity';

  return (
    <Layout links={links} pageName={pageName}>
      <div className="mx-auto max-w-3xl w-full px-4 sm:px-6 lg:px-8">
        <div className="space-y-12 mt-10">
          <div className="border-b border-gray-900/10 dark:border-gray-700 pb-12">
            <Notification className="mt-12" show={showNotification} setShow={setShowNotification}>
              <p className="text-sm font-medium text-gray-900 dark:text-white">Copié !</p>
              <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
                Le lien vers l&apos;abonnement iCal a été copié dans le presse-papier.
              </p>
            </Notification>
            <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">
              {translator('Mon profil')}
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-500">
              {translator('Informations liées à mon profil.')}
            </p>

            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <InputText disabled value={me?.id} label="Id" className="col-span-full" />
              <InputText disabled value={me?.email} label="Email" className="col-span-full" />
              <InputText disabled value={me?.username} label="Username" className="col-span-full" />
            </div>
          </div>

          {displayCalendar && (
            <div className="pb-12">
              <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">
                {translator('Intégration iCal')}
              </h2>
              <p className="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-500">
                {translator(
                  'Récupérez un abonnement iCal pour vos différents calendriers personnels.'
                )}
              </p>

              <div className="mt-10 border-t border-gray-100 dark:border-gray-700 col">
                <div className="divide-y divide-gray-100">
                  <div className="py-6 px-0 flex justify-between items-center">
                    <div className="text-sm font-medium leading-6 text-gray-900 dark:text-white">
                      Mon Calendrier
                    </div>
                    <button
                      type="button"
                      className="relative inline-flex items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10 isolate shadow-sm dark:text-white dark:bg-white/5 dark:hover:bg-white/10 dark:ring-white/10 leading-6 text-gray-700"
                      onClick={() => copyToClipboard(me)}>
                      <CalendarDaysIcon
                        className="-ml-0.5 h-5 w-5 dark:text-gray-400"
                        aria-hidden="true"
                      />
                      Copier le lien
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Profile;
